import { MuiImageSizeRendering } from 'types/types'
import { Theme } from '@mui/material/styles/createTheme'

const ImageSizeSettingWidth = (
  imageSize: MuiImageSizeRendering,
  theme: Theme,
) =>
  Object.keys(imageSize)
    .map(breakpoint => {
      let sizePart: string = ''
      switch (breakpoint) {
        case 'default':
          sizePart = `${imageSize[breakpoint]}px`
          break
        default:
          sizePart = `(min-width: ${theme.breakpoints.values[breakpoint]}px) ${imageSize[breakpoint]}px`
      }
      return sizePart
    })
    .join(', ')

const ImageSizeSettingHeight = (
  imageSize: MuiImageSizeRendering,
  theme: Theme,
) =>
  Object.keys(imageSize)
    .map(breakpoint => {
      let sizePart: string = ''
      switch (breakpoint) {
        case 'default':
          sizePart = `${imageSize[breakpoint]}px`
          break
        default:
          sizePart = `(min-height: ${theme.breakpoints.values[breakpoint]}px) ${imageSize[breakpoint]}px`
      }
      return sizePart
    })
    .join(', ')

export { ImageSizeSettingWidth, ImageSizeSettingHeight }
