import React from 'react'
import { errorLogger } from 'utils/errorLogger'
import { isBrowser } from 'utils/isBrowser'
const WaveSurfer = isBrowser() ? import('wavesurfer.js') : undefined

type PropTypes = {
  options: any
  isPlaying?: boolean
  audioPeaks: number[]
  audioFile?: string
  onPositionChange?: (data: any) => void
  onEvent?: (event: any) => void
  preload?: 'none' | 'metadata' | 'auto'
  children?: any
}
type StateTypes = {
  isReady: boolean
}

const dummyAudio =
  'data:audio/mp3;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU2LjM2LjEwMAAAAAAAAAAAAAAA//OEAAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAAEAAABIADAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV6urq6urq6urq6urq6urq6urq6urq6urq6v////////////////////////////////8AAAAATGF2YzU2LjQxAAAAAAAAAAAAAAAAJAAAAAAAAAAAASDs90hvAAAAAAAAAAAAAAAAAAAA//MUZAAAAAGkAAAAAAAAA0gAAAAATEFN//MUZAMAAAGkAAAAAAAAA0gAAAAARTMu//MUZAYAAAGkAAAAAAAAA0gAAAAAOTku//MUZAkAAAGkAAAAAAAAA0gAAAAANVVV'

class WaveBase extends React.Component<PropTypes, StateTypes> {
  _wavesurfer: any
  wavesurferEl: any
  constructor(props: PropTypes) {
    super(props)
    this.state = {
      isReady: false,
    }
    if (typeof WaveSurfer === undefined) {
      throw new Error('WaveSurfer undefined')
    }
  }

  async componentDidMount() {
    const { options, audioFile, audioPeaks, onEvent, isPlaying, preload } =
      this.props
    const optionsProp = { ...defaultOptions, ...options }
    const preloadProp = preload || 'none'

    if (!WaveSurfer) return

    await WaveSurfer.then(response => {
      this._wavesurfer = response.default.create({
        ...optionsProp,
        container: this?.wavesurferEl,
      })

      this._wavesurfer?.on('ready', e => {
        onEvent &&
          onEvent({ event: 'ready', value: e, wavesurfer: this._wavesurfer })
        this.setState({ isReady: true })
        /** When WaveSurfer isReady, use initial isPlaying state to play track initially */
        if (isPlaying && this._wavesurfer.isPlaying() === false)
          this._wavesurfer.play()
      })

      this._wavesurfer?.on('finish', e => {
        onEvent &&
          onEvent({
            event: 'finish',
            value: e,
            wavesurfer: this._wavesurfer,
          })
      })

      /** If audioFile is available load audio ELSE load dummyAudio and peaks (to render disabled track) */
      if (audioFile) {
        this._wavesurfer.params.forceDecode = false
        this._wavesurfer.load(audioFile, audioPeaks, preloadProp)
      }

      if (!audioFile && audioPeaks) {
        this._wavesurfer.load(dummyAudio, audioPeaks)
      }
    }).catch(error => {
      errorLogger(error)
      console.error('ERROR INITIALIZING WAVESURFER', error)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isPlaying) {
      if (this._wavesurfer?.isPlaying() === false) {
        this._wavesurfer?.play()
      }
    } else {
      if (this._wavesurfer?.isPlaying() && this._wavesurfer?.isReady) {
        this._wavesurfer?.pause()
      }
    }
  }

  // componentWillUnmount() {
  //   // Clear buffer
  //   delete this._wavesurfer.backend.buffer
  //   // unsubscribe all listeners
  //   this._wavesurfer.unAll()
  //   // destroy wavesurfer instance
  //   this._wavesurfer.destroy()
  // }

  render() {
    const childrenWithProps = this.props.children
      ? React.Children.map(this.props.children, child =>
          // @ts-ignore
          React.cloneElement(child, {
            wavesurfer: this._wavesurfer,
            isReady: this.state.isReady,
          }),
        )
      : false

    return (
      <div className='waveform'>
        <div
          className='wave'
          ref={c => {
            this.wavesurferEl = c
          }}
        />
        {this._wavesurfer && this.state.isReady && childrenWithProps}
      </div>
    )
  }
}
export { WaveBase }
export type AudioWavesBaseOptionTypes = {
  barWidth?: number
  barGap?: number
  barHeight?: number
  cursorWidth?: number
  cursorColor?: string
  height?: number
  hideScrollbar?: boolean
  progressColor?: string
  responsive?: boolean
  waveColor?: string
  backend?: 'MediaElement'
}

const defaultOptions = {
  height: 50,
  progressColor: '#fd7411',
  waveColor: '#4a4e60',
  barHeight: 1,
  barGap: 2,
  barWidth: 2,
  cursorWidth: 0,
  cursorColor: '#000000',
  hideScrollbar: true,
  responsive: true,
}
