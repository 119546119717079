import React from 'react'
import styled from '@mui/material/styles/styled'
import { AudioWavesBaseOptionTypes } from './WaveBase'
import { WaveBase } from './WaveBase'

export type RegionType = {
  id: string
  start: number
  end: number
  color: string
}

const Wave = styled('div')(() => ({
  width: '100%',
  position: 'relative',
  flexGrow: 1,
}))

type AudioWavesProps = {
  isPlaying: boolean
  audioFile: string
  audioPeaks: number[]
  wavesHeight?: number
  waveOptions?: AudioWavesBaseOptionTypes
  onEvent?: any
  onClickWaves: () => void
}

const sharedOptions = {
  barHeight: 1,
  barGap: 2,
  barWidth: 2,
  cursorWidth: 0,
  cursorColor: '#000000',
  hideScrollbar: true,
  responsive: true,
}

export const AudioWaves = ({
  isPlaying,
  audioFile,
  audioPeaks,
  wavesHeight = 80,
  waveOptions,
  onEvent,
  onClickWaves,
}: AudioWavesProps) => (
  <Wave onClick={() => onClickWaves()}>
    <WaveBase
      isPlaying={isPlaying}
      audioFile={audioFile}
      audioPeaks={audioPeaks}
      options={{
        ...sharedOptions,
        height: wavesHeight,
        progressColor: '#FB6064',
        waveColor: '#FDFDFD',
        backend: 'MediaElement',
        ...waveOptions,
      }}
      onEvent={onEvent}
    />
  </Wave>
)
