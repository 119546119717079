import { config } from 'configs/config'

/** Convert Firebase file object to file URL with media tag.
 * This will only work on files that are public */
export const fileToMediaURL = (file: { fullPath: string }) => {
  return file
    ? config.firebaseEmulator
      ? `https://firebasestorage.googleapis.com/v0/b/${
          config.firebaseConfig.storageBucket
        }/o/${file.fullPath.replace(/\//g, '%2F')}?alt=media`
      : `https://firebasestorage.googleapis.com/v0/b/${
          config.firebaseConfig.storageBucket
        }/o/${file.fullPath.replace(/\//g, '%2F')}?alt=media`
    : '/broken-image.png'
}
