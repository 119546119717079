import { Query } from 'firebase/firestore'
import { errorLogger } from 'utils/errorLogger'
import { useCollection } from 'react-firebase-hooks/firestore'

export const useDocsListener = (query: Query) => {
  const [snapshot, loading, errors] = useCollection(query)
  if (errors) {
    if (errors?.code !== 'permission-denied') {
      errorLogger(errors)
    }
  }
  const data: any = []
  snapshot?.docs.forEach(doc => data.push({ ...doc.data(), id: doc.id }))
  return { data, loading }
}
