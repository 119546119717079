import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { LinkTo } from './LinkTo'
import { IconButton } from 'components/IconButton'
import Facebook from '@mui/icons-material/Facebook'
import Instagram from '@mui/icons-material/Instagram'
import { ProducerKitsLogo } from './ProducerKitsLogo'
import { useTranslation } from 'react-i18next'

const FooterContainer = styled('div')(({ theme }) => ({
  borderTop: '4px solid #444',
  paddingTop: '56px',
  backgroundColor: 'rgba(22,22,22,1)',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}))
const IconButtonSocial = styled(IconButton)(() => ({
  borderRadius: 26,
  backgroundColor: 'rgba(53,53,53,1)',
}))
export const Footer = () => {
  const { t: theader } = useTranslation('header')
  const { t: tcommon } = useTranslation('common')
  return (
    <FooterContainer>
      <Container maxWidth='xl'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <ProducerKitsLogo />
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              variant='body1'
              sx={{ fontWeight: 700 }}
              color='textSecondary'
            >
              {tcommon<string>('named_home_title') as string}
            </Typography>
            <br />
            {[
              { label: theader('home'), route: '/' },
              { label: 'Explore', route: '/explore' },
            ].map(item => (
              <LinkTo
                key={item.route}
                sx={{ marginBottom: 2, display: 'block' }}
                color='textPrimary'
                variant='body2'
                href={item.route}
              >
                {item.label}
              </LinkTo>
            ))}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography
              variant='body1'
              sx={{ fontWeight: 700 }}
              color='textSecondary'
            >
              {theader('support') as string}
            </Typography>
            <br />
            {[
              { label: theader('faq'), route: '/frequently-asked-questions' },
              { label: theader('contact'), route: '/contact' },
            ].map(item => (
              <LinkTo
                key={item.route}
                sx={{ marginBottom: 2, display: 'block' }}
                color='textPrimary'
                variant='body2'
                href={item.route}
              >
                {item.label}
              </LinkTo>
            ))}
          </Grid>
          <Grid
            item
            container
            justifyContent='flex-end'
            xs={12}
            md={3}
            spacing={2}
          >
            <Grid item>
              <IconButtonSocial
                label='Instagram'
                size='large'
                onClick={e => {
                  e.stopPropagation()
                  window.open(
                    'https://www.instagram.com/producer.kits/',
                    '_blank',
                  )
                }}
              >
                <Instagram fontSize='inherit' />
              </IconButtonSocial>
            </Grid>
            <Grid item>
              <IconButtonSocial
                label='Facebook'
                size='large'
                onClick={e => {
                  e.stopPropagation()
                  window.open(
                    'https://www.facebook.com/producerkits.official/',
                    '_blank',
                  )
                }}
              >
                <Facebook fontSize='inherit' />
              </IconButtonSocial>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ padding: '32px 0px' }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <LinkTo
                variant='caption'
                href='/terms-of-use'
                color='textSecondary'
                underline='hover'
              >
                {tcommon<string>('terms_of_use') as string}
              </LinkTo>
              <LinkTo
                color='textSecondary'
                variant='caption'
                href='/privacy-policy'
                underline='hover'
                sx={{ marginLeft: '16px' }}
              >
                {tcommon<string>('privacy_policy') as string}
              </LinkTo>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
              <Typography variant='caption' color='textSecondary'>
                {
                  tcommon<string>('footer_copyrights', {
                    year: new Date().getFullYear(),
                  }) as string
                }
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* <button onClick={toggleDarkMode}>Toggle darkmode</button>
        {locales?.map((locale: string) => (
          <Link href={route} locale={locale} key={locale}>
            <button>{locale}</button>
          </Link>
        ))} */}
      </Container>
    </FooterContainer>
  )
}
