import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import styled from '@mui/material/styles/styled'
import CircularProgress from '@mui/material/CircularProgress'
import { IconButton } from 'components/IconButton'
import Favorite from '@mui/icons-material/Favorite'
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import Pause from '@mui/icons-material/Pause'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { MusicTrack_SS } from '@fivano/models'
import { fileToMediaURL } from 'utils/fileToMediaURL'
import { ProducerKitsButton } from '../../components/ProducerKitsButton'
import Image from 'next/image'
import { DownloadIcon } from 'icons/DownloadIcon'
import { playStateType } from 'containers/Tracks/TracksList'
import { AudioWaves } from 'containers/AudioWaves/AudioWaves'
import { useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'
import { convertToDate } from 'utils/convertToDate'
import { TrackListItemExpand } from './TrackListItemExpand'
import useTheme from '@mui/material/styles/useTheme'
import { ImageSizeSettingWidth } from 'components/ImageSizeSetting'

const GridListItem = styled(Grid)(({ theme }) => ({
  position: 'relative',
  height: 96,
  marginTop: 20,
  borderRadius: 10,
  transition: 'background 0.3s cubic-bezier(0, 0, 0, 1) 0s',
  cursor: 'pointer',
  backgroundColor: 'rgba(153,153,153,0.18)',
  ':hover': {
    backgroundColor: 'rgba(153,153,153,0.15)',
  },
  [theme.breakpoints.down('md')]: {
    height: 'unset',
    padding: '24px 0px 50px 0px',
    marginTop: 12,
  },
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    padding: '16px 0px 50px 0px',
  },
}))
const ButtonBaseImage = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  width: 64,
  height: 64,
  marginRight: 8,
  borderRadius: 10,
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    width: 56,
    height: 56,
  },
  [theme.breakpoints.down('sm')]: {
    width: 44,
    height: 44,
  },
}))
const NewTag = styled('div')(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: 'bold',
  padding: '1px 10px 0px 10px',
  position: 'absolute',
  backgroundImage:
    'linear-gradient(90deg, rgba(252,92,102,1) 0%, rgba(247,133,85,1) 100%)',
  right: -14,
  top: -9,
  borderRadius: 16,
  zIndex: 100,
  [theme.breakpoints.down('sm')]: {
    right: 60,
    top: 20,
  },
}))
export const GridTitle = styled(Grid)(({ theme }) => ({
  width: 196,
  paddingLeft: 36,
  [theme.breakpoints.down('xl')]: {
    width: 170,
    paddingLeft: 24,
  },
  [theme.breakpoints.down('lg')]: {
    width: 140,
    paddingLeft: 16,
  },
  [theme.breakpoints.down('md')]: {
    width: 120,
    paddingLeft: 20,
  },
  [theme.breakpoints.down('sm')]: {
    width: 'unset',
    flexGrow: 1,
    paddingLeft: 8,
    paddingTop: 8,
  },
}))
export const GridImage = styled(Grid)(({ theme }) => ({
  position: 'relative',
  paddingLeft: '36px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '24px',
    marginTop: '-4px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '12px',
  },
}))
const ButtonBaseExpand = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'expandTrack',
})<{ expandTrack: boolean }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  transition: 'background 0.3s cubic-bezier(0, 0, 0, 1) 0s',
  ':hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.03)',
  },
  height: 52,
  width: 52,
  [theme.breakpoints.down('lg')]: {},
  [theme.breakpoints.down('sm')]: {
    width: 40,
    height: 40,
  },
}))
const GridExpand = styled(Grid, {
  shouldForwardProp: prop => prop !== 'expandTrack',
})<{ expandTrack: boolean }>(({ theme, expandTrack }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 300ms ease',
  transform: expandTrack ? 'rotate(0deg)' : 'rotate(-90deg)',
  svg: {
    width: 42,
    height: 42,
  },
  paddingLeft: 24,
  paddingRight: 24,
  [theme.breakpoints.down('xl')]: {},
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: 12,
    paddingRight: 8,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 4,
    marginRight: 4,
    padding: 0,
    width: 40,
    height: 40,
    svg: {
      width: 32,
      height: 32,
    },
  },
}))
const GridWave = styled(Grid, {
  shouldForwardProp: prop => prop !== 'variant',
})<{ variant: 'small' | 'large' }>(({ theme, variant }) => ({
  width: 560,
  marginRight: 40,
  ...(variant === 'large' && {
    marginLeft: 40,
  }),
  ...(variant === 'small' && {
    height: '30px',
    marginTop: '-14px',
    overflow: 'hidden',
  }),
  padding: '0px 16px',
  [theme.breakpoints.down('xl')]: {
    width: 'unset',
    flexGrow: 1,
    padding: '0px 16px',
  },
  [theme.breakpoints.down('lg')]: {
    width: 'unset',
    flexGrow: 1,
    padding: '0px 8px',
    marginRight: 16,
    ...(variant === 'large' && {
      marginLeft: 0,
    }),
  },
  [theme.breakpoints.down('md')]: {
    top: '95px',
    position: 'absolute',
    width: '100%',
  },
}))
export const GridDetails = styled(Grid)(({ theme }) => ({
  width: 80,
  margin: '0px 12px',
  [theme.breakpoints.down(1400)]: {
    width: 60,
    margin: '0px 8px',
  },
  [theme.breakpoints.down('lg')]: {
    width: 45,
    margin: '0px 8px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))
export const GridDownload = styled(Grid)(({ theme }) => ({
  margin: '0px 24px',
  [theme.breakpoints.down('xl')]: {
    margin: '0px 16px',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '0px 8px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))
export const GridFavorite = styled(Grid)(({ theme }) => ({
  margin: '0px 0px 0px 0px',
  [theme.breakpoints.down('xl')]: {
    margin: '0px 24px 0px 16px',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '0px 16px 0px 8px',
  },
  [theme.breakpoints.down('md')]: {
    margin: '0px 16px 0px 4px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0px 16px 0px 4px',
  },
}))
export const PlayPauseButton = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'variant',
})<{ variant: 'small' | 'large' }>(({ variant, theme }) => ({
  width: variant === 'large' ? 38 : 26,
  height: variant === 'large' ? 38 : 26,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  color: 'rgba(0,0,0,0.8)',
  position: 'relative',
  '> svg': {
    width: variant === 'large' ? 28 : 20,
    height: variant === 'large' ? 28 : 20,
  },
  backgroundColor: 'rgba(255,255,255,1)',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  [theme.breakpoints.down('sm')]: {
    width: 26,
    height: 26,
    '> svg': {
      width: 20,
      height: 20,
    },
  },
}))

type TrackListItemProps = {
  playState: playStateType
  favorite?: boolean
  track: MusicTrack_SS
  onPlay: (
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
  ) => void
  onStop: (track: MusicTrack_SS, samplePreviewID: string) => void
  onPause: (track: MusicTrack_SS, samplePreviewID: string) => void
  onClickWaves: (track: MusicTrack_SS, samplePreviewID: string) => void
  onFavorite: (track: MusicTrack_SS, samplePreviewID: string) => void
  onDownload: (
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
    hasAccess: boolean,
  ) => void
  onExpandTrack?: (expandValue: boolean) => void
  downSM: boolean
  downMD: boolean
  downLG: boolean
  switchFavoriteForDownloadMobile?: boolean
  currentUser?: any
}
const NEW_TAG_DAYS_LIMIT = -70

export const TrackListItem = ({
  playState,
  favorite,
  track,
  onPlay,
  onPause,
  onStop,
  onClickWaves,
  onFavorite,
  onDownload,
  onExpandTrack,
  downSM,
  downMD,
  downLG,
  switchFavoriteForDownloadMobile = false,
  currentUser,
}: TrackListItemProps) => {
  const { t: ttracks } = useTranslation('tracks')
  const [loading, setLoading] = useState<boolean>(true)
  const [expandTrack, setExpandTrack] = useState<boolean>(false)
  const handleExpandTrack = () => {
    const newExpandValue = !expandTrack
    if (onExpandTrack) onExpandTrack(newExpandValue)
    setExpandTrack(newExpandValue)
  }
  const theme = useTheme()

  const trackSelected =
    playState.musicTrackID === track._id && playState.samplePreviewID === ''
  const isPlaying = trackSelected && playState.status === 'playing'
  const isPauzed = trackSelected && playState.status === 'pauzed'
  const isStopped = trackSelected && playState.status === 'stop'

  const handlePlayToggle = (track: MusicTrack_SS) => {
    /** Play if this track is not the selected track */
    if (!trackSelected) {
      onPlay(track, '', '')
    } else {
      /** Toggle play/pauze if track is selected */
      if (isPlaying) onPause(track, '')
      if (isPauzed || isStopped) onPlay(track, '', '')
    }
  }

  const handleEvent = (waveEvent: any) => {
    if (waveEvent.event === 'ready') {
      setLoading(false)
    }
    if (waveEvent.event === 'finish') {
      onStop(track, '')
    }
  }

  return (
    <>
      <GridListItem key={track._id} wrap='nowrap' container alignItems='center'>
        <GridExpand item expandTrack={expandTrack}>
          <ButtonBaseExpand
            expandTrack={expandTrack}
            onClick={handleExpandTrack}
          >
            <KeyboardArrowDown />
          </ButtonBaseExpand>
        </GridExpand>
        <Grid item>
          <PlayPauseButton
            variant={downSM ? 'small' : 'large'}
            onClick={() => handlePlayToggle(track)}
          >
            {loading && isPlaying && (
              <CircularProgress sx={{ position: 'absolute' }} />
            )}
            {trackSelected && isPlaying ? (
              <Pause color='inherit' />
            ) : (
              <PlayArrow color='inherit' />
            )}
          </PlayPauseButton>
        </Grid>

        <GridImage item>
          {differenceInDays(convertToDate(track.createdAt), new Date()) >
            NEW_TAG_DAYS_LIMIT &&
            !downSM && <NewTag>{ttracks<string>('new_track_image')}</NewTag>}
          <ButtonBaseImage>
            <Image
              layout='fill'
              objectFit='cover'
              placeholder='blur'
              blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOM/g8AAbsBXM7nFtsAAAAASUVORK5CYII='
              alt={track.nameTrack}
              src={fileToMediaURL(track.musicTrackImages?.[0])}
              sizes={ImageSizeSettingWidth(
                { sm: 44, md: 56, default: 64 },
                theme,
              )}
            />
          </ButtonBaseImage>
        </GridImage>

        <GridTitle item sm={downMD}>
          <Typography sx={{ fontWeight: 700 }} noWrap>
            {track.nameTrack}
          </Typography>
          {downMD && (
            <Typography
              variant='body1'
              color='textSecondary'
              noWrap
              marginTop='-2px'
              gutterBottom
            >
              {track.keyTrack?.label} / {track.bpm} BPM
            </Typography>
          )}
        </GridTitle>

        <GridWave
          container
          item
          alignItems='center'
          variant={downMD ? 'small' : 'large'}
          position='relative'
        >
          {track.masterMp3?.[0] && (
            <AudioWaves
              wavesHeight={downMD ? 57 : 80}
              isPlaying={isPlaying}
              audioFile={fileToMediaURL({
                fullPath: removeLeadingSlash(track.masterMp3?.[0].fullPath),
              })}
              audioPeaks={track.masterMp3?.[0]?.waves || []}
              onClickWaves={() => onClickWaves(track, '')}
              onEvent={handleEvent}
            />
          )}
        </GridWave>

        <GridDetails item>
          <Typography variant='body1' sx={{ fontWeight: 700 }}>
            BPM
          </Typography>
          <Typography variant='body1' gutterBottom>
            {track.bpm}
          </Typography>
        </GridDetails>

        <GridDetails item>
          <Typography
            variant='body1'
            sx={{ fontWeight: 700, whiteSpace: 'nowrap' }}
          >
            Key
          </Typography>
          <Typography variant='body1' gutterBottom>
            {track.keyTrack?.label}
          </Typography>
        </GridDetails>

        {!downLG && (
          <GridDownload item xs={1}>
            {!expandTrack && (
              <ProducerKitsButton size='small' onClick={handleExpandTrack}>
                {ttracks<string>('track_download_text')}
              </ProducerKitsButton>
            )}
          </GridDownload>
        )}

        <GridFavorite item>
          {switchFavoriteForDownloadMobile ? (
            <IconButton
              label={ttracks<string>('track_download_text')}
              onClick={handleExpandTrack}
              size={downSM ? 'medium' : 'large'}
            >
              <DownloadIcon />
            </IconButton>
          ) : (
            <>
              <IconButton
                label={
                  favorite
                    ? ttracks<string>('track_remove_favorite_text')
                    : ttracks<string>('track_favorite_text')
                }
                onClick={() => onFavorite(track, '')}
                size={downSM ? 'medium' : 'large'}
              >
                {favorite ? (
                  <Favorite color='primary' />
                ) : (
                  <FavoriteBorderOutlined />
                )}
              </IconButton>
              {differenceInDays(convertToDate(track.createdAt), new Date()) >
                NEW_TAG_DAYS_LIMIT &&
                downSM && <NewTag>{ttracks<string>('new_track_image')}</NewTag>}
            </>
          )}
        </GridFavorite>
      </GridListItem>
      <TrackListItemExpand
        loading={loading}
        playState={playState}
        track={track}
        onPlay={onPlay}
        onStop={onStop}
        onPause={onPause}
        onClickWaves={onClickWaves}
        onDownload={onDownload}
        onEvent={handleEvent}
        downSM={downSM}
        downMD={downMD}
        expandTrack={expandTrack}
        currentUser={currentUser}
      />
    </>
  )
}

export const removeLeadingSlash = (str: string) => {
  return str.replace(/^\/+/, '')
}
