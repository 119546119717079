import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { ProducerKitsButton } from './ProducerKitsButton'
import { Trans, useTranslation } from 'react-i18next'
import { useModalRouter } from 'hooks/useModalRouter'
import { LinkClick } from './LinkClick'

type ActivatePlanModalTypes = {
  onClose: any
}

export const ActivatePlanModal = ({ onClose }: ActivatePlanModalTypes) => {
  const { t } = useTranslation('modals')
  const { t: taccount } = useTranslation('account')
  const { t: tcommon } = useTranslation('common')
  const { openModal } = useModalRouter()
  return (
    <Dialog open onClose={onClose} maxWidth='xs'>
      <DialogTitle>{t<string>('activePlan_title_text') as string}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          <Trans
            i18nKey='download_no_sub_message'
            t={taccount}
            components={[
              <LinkClick onClick={() => openModal({ form: 'plans' })} />,
            ]}
          />
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0px 24px 24px 24px' }}>
        <ProducerKitsButton onClick={onClose} size='small'>
          {tcommon<string>('close') as string}
        </ProducerKitsButton>
      </DialogActions>
    </Dialog>
  )
}
