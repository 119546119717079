import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import styled from '@mui/material/styles/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles/createTheme'
import { TrackListItem } from 'containers/Tracks/TrackListItem'
import { ProducerKitsButton } from 'components/ProducerKitsButton'
import { MusicTrack_SS } from '@fivano/models'
import { SignInFirstModal } from 'components/SignInFirstModal'
import { ActivatePlanModal } from 'components/ActivatePlanModal'
import { useTranslation } from 'react-i18next'
import { onSnapshot, doc, updateDoc } from 'firebase/firestore'
import { firebaseObject } from 'containers/firebaseObject'
import { dataLayerPush } from 'containers/GoogleTracking/utils'
import { useFunctionRequest } from 'hooks/useFunctionRequest'
import { useSnackbar } from 'components/useSnackbar'
import { errorLogger } from 'utils/errorLogger'
import { useRouter } from 'next/router'

export type playStateType = {
  musicTrackID: string | null
  samplePreviewID: string | null
  status: 'playing' | 'pauzed' | 'stop'
}

const TrackListContainer = styled(Container)(() => ({
  width: '100%',
  position: 'relative',
}))

type TracksListTypes = {
  tracks: MusicTrack_SS[]
  currentUser?: any
  playState: playStateType
  onPlayStateChange: (object: playStateType) => void
  tracksLimit?: number
  switchFavoriteForDownloadMobile?: boolean
  disableShowMore?: boolean
  onExpandTrack?: (expandValue: boolean) => void
}

export const TracksList = ({
  currentUser,
  tracks,
  playState,
  onPlayStateChange,
  onExpandTrack,
  tracksLimit = 10,
  switchFavoriteForDownloadMobile = false,
  disableShowMore = false,
}: TracksListTypes) => {
  const { openSnack, Snackbar } = useSnackbar()
  const { query, replace } = useRouter()
  const makeRequest = useFunctionRequest()
  const { t: ttracks } = useTranslation('tracks')
  const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  const [limitTracks, setLimitTracks] = useState<number>(tracksLimit)
  const [person, setPerson] = useState<any>()
  useEffect(() => {
    if (currentUser?.uid) {
      onSnapshot(
        doc(firebaseObject.db, 'persons', currentUser?.uid),
        querySnapshot => {
          setPerson(querySnapshot.data())
        },
      )
    }
  }, [currentUser?.uid])

  const handlePlay = (
    track: MusicTrack_SS,
    samplePreviewID?: string,
    samplePreviewName?: string,
  ) => {
    const currentPlaylist = track.playlists[0]?.playlist

    if (
      playState.musicTrackID === track._id &&
      playState.samplePreviewID === samplePreviewID &&
      samplePreviewID !== ''
    ) {
      dataLayerPush('navigate_sample', {
        trackName: track.nameTrack,
        trackID: track._id,
        samplePreviewName: samplePreviewName,
        samplePreviewID: samplePreviewID,
        playlistName: currentPlaylist?.label,
        playlistID: currentPlaylist?.value,
        email: currentUser?.email || 'NO_USER', // email address string OR NO_USER
      })
    } else if (
      playState.musicTrackID === track._id &&
      (samplePreviewID === undefined || samplePreviewID === '')
    ) {
      dataLayerPush('navigate_track', {
        trackName: track.nameTrack,
        trackID: track._id,
        playlistName: currentPlaylist?.label,
        playlistID: currentPlaylist?.value,
        email: currentUser?.email || 'NO_USER', // email address string OR NO_USER
      })
    } else if (samplePreviewID !== undefined && samplePreviewID !== '') {
      dataLayerPush('play_sample', {
        trackName: track.nameTrack,
        trackID: track._id,
        samplePreviewName: samplePreviewName,
        samplePreviewID: samplePreviewID,
        playlistName: currentPlaylist?.label,
        playlistID: currentPlaylist?.value,
        email: currentUser?.email || 'NO_USER', // email address string OR NO_USER
      })
    } else {
      dataLayerPush('play_track', {
        trackName: track.nameTrack,
        trackID: track._id,
        playlistName: currentPlaylist?.label,
        playlistID: currentPlaylist?.value,
        email: currentUser?.email || 'NO_USER', // email address string OR NO_USER
      })
    }
    onPlayStateChange({
      musicTrackID: track._id,
      status: 'playing',
      samplePreviewID: samplePreviewID || '',
    })
  }

  const handleStop = (track: MusicTrack_SS, samplePreviewID?: string) => {
    onPlayStateChange({
      musicTrackID: track._id,
      status: 'stop',
      samplePreviewID: samplePreviewID || '',
    })
  }

  const handlePause = (track: MusicTrack_SS, samplePreviewID?: string) => {
    onPlayStateChange({
      musicTrackID: track._id,
      status: 'pauzed',
      samplePreviewID: samplePreviewID || '',
    })
  }

  const [signInFirstModal, setSignInFirstModal] = useState(false)
  const [activePlanModal, setActivePlanModal] = useState(false)
  const accessCheck = callback => {
    if (!currentUser) {
      setSignInFirstModal(true)
      return
    }
    callback()
  }

  const handleFavorite = (track: MusicTrack_SS) => {
    accessCheck(() => {
      const currentFavorites = person?.favoriteTracks || []
      const newFavorites = [...currentFavorites]
      const trackIndex = currentFavorites.indexOf(track._id)
      const currentPlaylist = track.playlists[0]?.playlist
      if (trackIndex === -1) {
        newFavorites.push(track._id)
      } else {
        newFavorites.splice(trackIndex, 1)
      }
      updateDoc(doc(firebaseObject.db, 'persons', currentUser.uid), {
        favoriteTracks: newFavorites,
      })
      dataLayerPush(trackIndex === -1 ? 'add_favorite' : 'remove_favorite', {
        trackName: track.nameTrack,
        trackID: track._id,
        playlistName: currentPlaylist?.label,
        playlistID: currentPlaylist?.value,
        email: currentUser?.email || 'NO_USER', // email address string OR NO_USER
      })
    })
  }

  const handleDownload = (
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
    hasAccess: boolean,
  ) => {
    const currentPlaylist = track.playlists[0]?.playlist

    if (!currentUser?.uid) {
      replace(
        {
          query: { ...query, subscribe: 'plans' },
        },
        undefined,
        { scroll: false },
      )
      return
    }

    if (hasAccess) {
      makeRequest({
        functionName: 'requestDownloadURL',
        body: {
          musicTrackID: track._id,
          previewFileID: samplePreviewID,
          samplePreviewName,
        },
      })
        .then(response => {
          dataLayerPush('download_track', {
            email: currentUser?.email || 'NO_USER', // email address string OR NO_USER
            trackName: track.nameTrack,
            trackID: track._id,
            samplePreviewName: samplePreviewName,
            samplePreviewID: samplePreviewID,
            playlistName: currentPlaylist?.label,
            playlistID: currentPlaylist?.value,
            previewFileID: samplePreviewID,
            previewFileName: samplePreviewName,
          })
          downLoadMultiple(
            response?.downloadURLs.map(file => ({
              download: file.downloadURL,
              filename: file.filename,
            })),
          )
          openSnack({
            message: 'Download started',
            severity: 'success',
          })
        })
        .catch(error => {
          errorLogger(error, 'tracklist')
          openSnack({ message: error.message, severity: 'error' })
        })
    } else {
      makeRequest({
        functionName: 'requestCreditTransaction',
        body: {
          musicTrackID: track._id,
          previewFileID: samplePreviewID,
          previewFileName: samplePreviewID,
        },
      })
        .then(() => {
          openSnack({
            message: 'Samples aquired, you can download now',
            severity: 'success',
          })
        })
        .catch(error => {
          errorLogger(error, 'tracklist')
          openSnack({ message: error.message, severity: 'error' })
        })
    }
  }

  return (
    <TrackListContainer maxWidth='xl' disableGutters>
      <Snackbar />
      {signInFirstModal && (
        <SignInFirstModal onClose={() => setSignInFirstModal(false)} />
      )}
      {activePlanModal && (
        <ActivatePlanModal onClose={() => setActivePlanModal(false)} />
      )}
      {tracks?.slice(0, limitTracks)?.map(item => {
        const favorite = person?.favoriteTracks?.includes(item._id)
        return (
          <TrackListItem
            key={item._id}
            track={item}
            playState={playState}
            favorite={favorite}
            onPlay={handlePlay}
            onStop={handleStop}
            onPause={handlePause}
            onClickWaves={handlePlay}
            onFavorite={handleFavorite}
            onDownload={handleDownload}
            onExpandTrack={onExpandTrack}
            downSM={downSM}
            downMD={downMD}
            downLG={downLG}
            switchFavoriteForDownloadMobile={
              downSM && switchFavoriteForDownloadMobile
            }
            currentUser={currentUser}
          />
        )
      })}
      {!disableShowMore && currentUser && tracks?.length > limitTracks && (
        <Grid container justifyContent='center'>
          <Box pt={2}>
            <ProducerKitsButton
              size='medium'
              onClick={() => setLimitTracks(limitTracks + 5)}
            >
              {ttracks<string>('playlist_more')}
            </ProducerKitsButton>
          </Box>
        </Grid>
      )}
    </TrackListContainer>
  )
}

// https://stackoverflow.com/a/29606450/19354772
/** Download a list of files.
 * @author speedplane
 */
function downLoadMultiple(files: { download: string; filename: string }[]) {
  function download_next(i) {
    if (i >= files.length) {
      return
    }
    var a = document.createElement('a')
    a.href = files[i].download
    a.target = '_parent'
    // Use a.download if available, it prevents plugins from opening.
    if ('download' in a) {
      a.download = files[i].filename
    }
    // Add a to the doc for click to work.
    ;(document.body || document.documentElement).appendChild(a)
    if (a?.click) {
      a.click() // The click method is supported by most browsers.
    }
    // Delete the temporary link.
    // @ts-ignore
    a.parentNode.removeChild(a)
    // Download the next file with a small timeout. The timeout is necessary
    // for IE, which will otherwise only download the first file.
    setTimeout(function () {
      download_next(i + 1)
    }, 1500)
  }
  // Initiate the first download.
  download_next(0)
}
