import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import styled from '@mui/material/styles/styled'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import { IconButton } from 'components/IconButton'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Pause from '@mui/icons-material/Pause'
import { CreditTransaction_SS, MusicTrack_SS } from '@fivano/models'
import { fileToMediaURL } from 'utils/fileToMediaURL'
import { ProducerKitsButton } from '../../components/ProducerKitsButton'
import Image from 'next/image'
import { DownloadIcon } from 'icons/DownloadIcon'
import { playStateType } from 'containers/Tracks/TracksList'
import { AudioWaves } from 'containers/AudioWaves/AudioWaves'
import { useTranslation } from 'react-i18next'
import { query, collection, where } from 'firebase/firestore'
import { useDocsListener } from 'hooks/useDocsListener'
import { firebaseObject } from 'containers/firebaseObject'
import {
  PlayPauseButton,
  GridDetails,
  GridDownload,
  GridFavorite,
  GridTitle,
  GridImage,
} from './TrackListItem'
import useTheme from '@mui/material/styles/useTheme'
import { ImageSizeSettingWidth } from 'components/ImageSizeSetting'

const ExpandArea = styled(Collapse)(({ theme }) => ({
  marginLeft: '160px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '28px',
  },
}))
const GridListItemSmall = styled(Grid)(({ theme }) => ({
  position: 'relative',
  height: 52,
  padding: '6px 0px',
  margonBottom: '1px',
  cursor: 'pointer',
  backgroundColor: 'rgba(153,153,153,0.18)',
  ':hover': {
    backgroundColor: 'rgba(153,153,153,0.15)',
  },
  [theme.breakpoints.down('md')]: {
    padding: '16px 0px 0px 0px',
    height: 90,
  },
}))
const GridPlayPause = styled(Grid)(({ theme }) => ({
  paddingLeft: '32px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '20px',
  },
}))
const ButtonBaseImageSmall = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  width: 34,
  height: 34,
  borderRadius: 10,
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 8,
    marginRight: 16,
    width: 28,
    height: 28,
  },
}))
const GridExpandHeader = styled(Grid)(({ theme }) => ({
  marginTop: '20px',
  padding: '32px',
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',
  borderBottom: '2px solid',
  borderColor: theme.palette.divider,
  backgroundColor: 'rgba(153,153,153,0.18)',
  [theme.breakpoints.down('md')]: {
    marginTop: '12px',
    padding: '24px 32px',
  },
}))
const TypographyExpandHeader = styled(Typography)(({ theme }) => ({
  width: 304,
  display: 'inline-block',
  fontWeight: 'bold',
  [theme.breakpoints.down('xl')]: {
    width: 276,
  },
  [theme.breakpoints.down('lg')]: {
    width: 244,
  },
  [theme.breakpoints.down('md')]: {
    display: 'inline',
    paddingRight: 8,
  },
}))
const GridExpandSpacer = styled(Grid)(({ theme }) => ({
  height: '16px',
  backgroundColor: 'rgba(153,153,153,0.18)',
  [theme.breakpoints.down('sm')]: {
    marginTop: '12px',
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
  },
}))
const GridExpandFooter = styled(Grid)(() => ({
  height: '16px',
  backgroundColor: 'rgba(153,153,153,0.18)',
  borderBottomRightRadius: '10px',
  borderBottomLeftRadius: '10px',
}))
const GridWaveExpand = styled(Grid, {
  shouldForwardProp: prop => prop !== 'variant',
})<{ variant: 'small' | 'large' }>(({ theme, variant }) => ({
  width: 560,
  marginRight: 40,
  ...(variant === 'large' && {
    marginLeft: 40,
  }),
  ...(variant === 'small' && {
    height: '30px',
    marginTop: '-14px',
    overflow: 'hidden',
  }),
  padding: '0px 16px',
  [theme.breakpoints.down('xl')]: {
    width: 'unset',
    flexGrow: 1,
    padding: '0px 16px',
  },
  [theme.breakpoints.down('lg')]: {
    width: 'unset',
    flexGrow: 1,
    padding: '0px 16px',
    ...(variant === 'large' && {
      marginLeft: 0,
    }),
  },
  [theme.breakpoints.down('md')]: {
    top: '62px',
    position: 'absolute',
    width: '100%',
    overflow: 'hidden',
  },
}))

type TrackListItemProps = {
  expandTrack: boolean
  playState: playStateType
  track: MusicTrack_SS
  onPlay: (
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
  ) => void
  onStop: (track: MusicTrack_SS, samplePreviewID: string) => void
  onPause: (track: MusicTrack_SS, samplePreviewID: string) => void
  onClickWaves: (
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
  ) => void
  onEvent: (
    value: any,
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
  ) => void
  loading: boolean
  onDownload: (
    track: MusicTrack_SS,
    samplePreviewID: string,
    samplePreviewName: string,
    hasAccess: boolean,
  ) => void
  downSM: boolean
  downMD: boolean
  currentUser?: any
}

export const TrackListItemExpand = ({
  expandTrack,
  playState,
  track,
  onPlay,
  onPause,
  onClickWaves,
  onDownload,
  onEvent,
  loading,
  downSM,
  downMD,
  currentUser,
}: TrackListItemProps) => {
  const { t: ttracks } = useTranslation('tracks')
  const {
    data: creditTransactions,
    loading: loadingCreditTransactions,
  }: { data: CreditTransaction_SS[]; loading: boolean } = useDocsListener(
    query(
      collection(firebaseObject.db, 'creditTransactions'),
      where('uid', '==', currentUser?.uid || 'USER_UID_UNDEFINED'),
      where('musicTrackID', '==', track?._id || 'NOID'),
    ),
  )
  const theme = useTheme()

  return (
    <ExpandArea in={expandTrack}>
      {downMD ? (
        <GridExpandHeader item xs={12}>
          <TypographyExpandHeader>
            Download Separate Samples{' '}
          </TypographyExpandHeader>
          <br />
          <Typography display='inline' variant='body1' color='textSecondary'>
            {' '}
            Including WAV stem + one-shot sample + MIDI
          </Typography>
        </GridExpandHeader>
      ) : (
        <GridExpandHeader item xs={12}>
          <TypographyExpandHeader>
            Download Separate Samples{' '}
          </TypographyExpandHeader>
          <Typography display='inline'>
            {' '}
            Including WAV stem + one-shot sample + MIDI
          </Typography>
        </GridExpandHeader>
      )}

      <GridExpandSpacer item xs={12} />
      {track?.previewsMp3.map(preview => {
        const trackPreviewSelected =
          playState.musicTrackID === track._id &&
          playState.samplePreviewID === preview.fileID
        const isPlayingPreview =
          trackPreviewSelected && playState.status === 'playing'
        const isPauzedPreview =
          trackPreviewSelected && playState.status === 'pauzed'
        const isStoppedPreview =
          trackPreviewSelected && playState.status === 'stop'

        const handlePlayPreviewToggle = (
          track: MusicTrack_SS,
          samplePreviewID: string,
        ) => {
          /** Play if this track is not the selected track */
          if (!trackPreviewSelected) {
            onPlay(
              track,
              samplePreviewID,
              convertSamplePreviewName(preview.name),
            )
          } else {
            /** Toggle play/pauze if track is selected */
            if (isPlayingPreview) onPause(track, samplePreviewID)
            if (isPauzedPreview || isStoppedPreview)
              onPlay(
                track,
                samplePreviewID,
                convertSamplePreviewName(preview.name),
              )
          }
        }

        const isDownloaded = creditTransactions?.some(
          ct => ct.previewFileID === preview.fileID,
        )

        return (
          <GridListItemSmall
            container
            wrap='nowrap'
            alignItems={downMD ? 'flex-start' : 'center'}
            key={preview.fileID}
          >
            <GridPlayPause item>
              <PlayPauseButton
                variant='small'
                onClick={() => handlePlayPreviewToggle(track, preview.fileID)}
              >
                {loading && isPlayingPreview && (
                  <CircularProgress sx={{ position: 'absolute' }} size={26} />
                )}
                {isPlayingPreview ? (
                  <Pause color='inherit' />
                ) : (
                  <PlayArrow color='inherit' />
                )}
              </PlayPauseButton>
            </GridPlayPause>

            <GridImage item {...(downSM && { display: 'none' })}>
              <ButtonBaseImageSmall>
                <Image
                  layout='fill'
                  objectFit='cover'
                  placeholder='blur'
                  blurDataURL='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mOM/g8AAbsBXM7nFtsAAAAASUVORK5CYII='
                  alt={track.nameTrack}
                  src={fileToMediaURL(track.musicTrackImages?.[0])}
                  sizes={ImageSizeSettingWidth({ sm: 28, default: 34 }, theme)}
                />
              </ButtonBaseImageSmall>
            </GridImage>

            <GridTitle
              item
              sm={downMD}
              {...(downSM && {
                style: { padding: '2px 0px 0px 14px' },
              })}
            >
              <Typography sx={{ fontWeight: 700 }} noWrap>
                {convertSamplePreviewName(preview.name)}
              </Typography>
            </GridTitle>

            <GridWaveExpand container item alignItems='center' variant='small'>
              {track.masterMp3?.[0] && (
                <AudioWaves
                  wavesHeight={60}
                  isPlaying={isPlayingPreview}
                  audioFile={fileToMediaURL({
                    fullPath: removeLeadingSlash(preview.fullPath),
                  })}
                  audioPeaks={preview.waves || []}
                  onClickWaves={() =>
                    onClickWaves(
                      track,
                      preview.fileID,
                      convertSamplePreviewName(preview.name),
                    )
                  }
                  onEvent={event =>
                    onEvent(
                      event,
                      track,
                      preview.fileID,
                      convertSamplePreviewName(preview.name),
                    )
                  }
                />
              )}
            </GridWaveExpand>

            <GridDetails item>
              <Typography variant='body1'>{track.bpm}</Typography>
            </GridDetails>

            <GridDetails item>
              <Typography variant='body1'>{track.keyTrack?.label}</Typography>
            </GridDetails>

            <GridDownload item>
              <ProducerKitsButton
                sx={{ height: '24px' }}
                size='small'
                disabled={currentUser?.uid && loadingCreditTransactions}
                onClick={() =>
                  onDownload(
                    track,
                    preview.fileID,
                    convertSamplePreviewName(preview.name),
                    isDownloaded,
                  )
                }
              >
                {currentUser?.uid && loadingCreditTransactions
                  ? 'Loading'
                  : isDownloaded
                  ? 'Download'
                  : '1 Credit'}
              </ProducerKitsButton>
            </GridDownload>

            <GridFavorite item>
              {downSM ? (
                <ProducerKitsButton
                  sx={{ height: '24px' }}
                  size='small'
                  disabled={currentUser?.uid && loadingCreditTransactions}
                  onClick={() =>
                    onDownload(
                      track,
                      preview.fileID,
                      convertSamplePreviewName(preview.name),
                      isDownloaded,
                    )
                  }
                >
                  {currentUser?.uid && loadingCreditTransactions
                    ? 'Loading'
                    : isDownloaded
                    ? 'Download'
                    : '1 Credit'}
                </ProducerKitsButton>
              ) : (
                <IconButton
                  sx={{ marginTop: downMD ? '-10px' : '0px' }}
                  label={ttracks<string>('track_download_text')}
                  onClick={() =>
                    onDownload(
                      track,
                      preview.fileID,
                      convertSamplePreviewName(preview.name),
                      isDownloaded,
                    )
                  }
                  size={downSM ? 'small' : 'large'}
                  disabled={currentUser?.uid && !isDownloaded}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </GridFavorite>
          </GridListItemSmall>
        )
      })}
      <GridExpandFooter item xs={12} />
    </ExpandArea>
  )
}

const removeLeadingSlash = (str: string) => str.replace(/^\/+/, '')

export const convertSamplePreviewName = (name: string) =>
  name?.replace(/^\d+\s+/, '').replace('.mp3', '')
