export const DownloadIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g transform='translate(7.385)'>
      <path
        fill='currentColor'
        d='M33.322,9.371a.641.641,0,0,0-.585-.377h-2.57V.642A.643.643,0,0,0,29.525,0h-2.57a.643.643,0,0,0-.642.642V8.995h-2.57a.642.642,0,0,0-.483,1.065l4.5,5.14a.641.641,0,0,0,.966,0l4.5-5.14A.641.641,0,0,0,33.322,9.371Z'
        transform='translate(-23.1)'
      />
    </g>
    <g transform='translate(2.887 14.135)'>
      <path
        fill='currentColor'
        d='M19.592,63.525V67.38H5.457V63.525H2.887v5.14A1.285,1.285,0,0,0,4.172,69.95h16.7a1.284,1.284,0,0,0,1.285-1.285v-5.14Z'
        transform='translate(-2.887 -63.525)'
      />
    </g>
  </svg>
)
